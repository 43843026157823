<div class="slideshow-container">
    <div class="slide" *ngFor="let slide of slides; let i = index" [class.active]="i === currentSlide">
      <h1>{{ slide.title }}</h1>
      <p>{{ slide.description }}</p>
      <p class="slide-content">{{ slide.content }}</p>
    </div>
  
    <button class="prev" (click)="previousSlide()">&#10094;</button>
    <button class="next" (click)="nextSlide()">&#10095;</button>
  </div>
  
  <div class="services">
    <h2>Our Services</h2>
    <div class="service-cards">
      <div class="card" *ngFor="let service of services">
        <h3>{{ service.title }}</h3>
        <p>{{ service.description }}</p>
      </div>
    </div>
  </div>
  