
<nav class="navbar">
  <div class="logo">TechConsultantPro</div>
  <ul class="nav-links">
    <li><a routerLink="/home" routerLinkActive="active">Home</a></li>
    <li><a routerLink="/services" routerLinkActive="active">Services</a></li>
    <li><a routerLink="/projects" routerLinkActive="active">Projects</a></li>
    <li><a routerLink="/about-us" routerLinkActive="active">About Us</a></li>
    <li><a routerLink="/contact-us" routerLinkActive="active">Contact Us</a></li>
  </ul>
</nav>

<div [@routeAnimations]="getRouteAnimationData()">
  <router-outlet></router-outlet>
</div>
