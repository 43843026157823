import { Component } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent {
  aboutUsContent = {
    title: 'About TechConsultantPro',
    description: `At TechConsultantPro, we specialize in delivering customized solutions for website development, cloud-based projects, and ETL workflows. 
      With over a decade of industry experience, we have built a reputation for delivering excellence in web technology and cloud services.`,
    mission: `Our mission is to empower businesses with cutting-edge technology solutions that drive growth, improve operational efficiency, 
      and provide exceptional value. We are committed to building long-lasting relationships with our clients by offering reliable, scalable, and secure services.`,
    values: [
      'Innovation: Always stay ahead with the latest technological trends.',
      'Integrity: Build trust through transparency and ethical practices.',
      'Client Satisfaction: Exceed expectations in every project.',
      'Collaboration: Work as partners with our clients to achieve mutual success.'
    ]
  };
}
