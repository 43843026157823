<div class="about-container">
    <div class="about-background">
      <h2>{{ aboutUsContent.title }}</h2>
      
      <div class="about-description">
        <p>{{ aboutUsContent.description }}</p>
      </div>
  
      <div class="about-mission">
        <h3>Our Mission</h3>
        <p>{{ aboutUsContent.mission }}</p>
      </div>
  
      <div class="about-values">
        <h3>Our Core Values</h3>
        <ul>
          <li *ngFor="let value of aboutUsContent.values">
            {{ value }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  