import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { ProjectsComponent } from './projects/projects.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';

const routes: Routes = [
  { path: '', component: HomeComponent },         // Home path (default route)
  { path: 'services', component: ServicesComponent,data: { animation: 'togglePage' } },
  { path: 'projects', component: ProjectsComponent,data: { animation: 'togglePage' } },
  { path: 'contact-us', component: ContactUsComponent ,data: { animation: 'togglePage' }},
  { path: 'about-us', component: AboutUsComponent ,data: { animation: 'togglePage' }},
  { path: '**', redirectTo: '', pathMatch: 'full' }  // Wildcard route for handling invalid paths
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
