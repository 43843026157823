import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  projects = [
    {
      title: 'Website Development & Maintenance',
      description: `Our website development service includes creating fully responsive, high-performance websites tailored to your business needs. 
      We focus on user experience (UX), accessibility, and search engine optimization (SEO) to ensure your website reaches its full potential. 
      Our maintenance services ensure your website remains secure, up-to-date, and continues to perform optimally over time.`
    },
    {
      title: 'Azure and Cloud Solutions',
      description: `We provide expert cloud solutions, specializing in Azure. Our services include cloud architecture design, migration, deployment, and management. 
      We help businesses transition to the cloud, ensuring seamless integration with existing systems and maximizing efficiency while minimizing costs. 
      Our ongoing support ensures your cloud infrastructure is always optimized and secure.`
    },
    {
      title: 'ETL Services with Azure Data Factory & Databricks',
      description: `Our ETL (Extract, Transform, Load) services streamline your data processing workflows using Azure Data Factory and Databricks. 
      We specialize in data integration, transformation, and analytics, helping businesses derive actionable insights from their data. 
      Our solutions are scalable and can handle complex data environments, ensuring your data is always ready for analysis and decision-making.`
    }
  ];  

}
