import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  currentSlide = 0;

  slides = [
    {
      title: 'Welcome to TechConsultantPro',
      description: 'We provide top-notch consulting services for website development, maintenance projects, and cloud technologies.',
      content: 'Our expertise spans Azure and a wide range of cloud solutions, including ETL services with Azure Data Factory and Databricks.'
    },
    {
      title: 'Website Development & Maintenance',
      description: 'Tailored website development and continuous maintenance to keep your online presence strong.',
      content: 'Our team ensures high performance, security, and up-to-date content to engage your audience effectively.'
    },
    {
      title: 'Azure and Cloud Solutions',
      description: 'Harness the power of the cloud with our expert Azure solutions.',
      content: 'From architecture to deployment and management, we optimize your cloud operations for better scalability and cost-efficiency.'
    },
    {
      title: 'ETL Services with Azure Data Factory & Databricks',
      description: 'Efficient data workflows to drive your business insights.',
      content: 'We specialize in ETL services using Azure Data Factory and Databricks, ensuring seamless data integration, transformation, and analytics.'
    }
  ];

  services = [
    {
      title: 'Website Development & Maintenance',
      description: 'We design and develop websites tailored to your business needs, ensuring high performance and responsive designs. Our maintenance services keep your site secure, up-to-date, and running smoothly.'
    },
    {
      title: 'Azure and Cloud Solutions',
      description: 'Leverage the power of Azure and other cloud platforms to scale your business. We provide expert guidance in cloud architecture, deployment, and management to ensure optimal performance and cost-efficiency.'
    },
    {
      title: 'ETL Services with Azure Data Factory & Databricks',
      description: 'Streamline your data workflows with our ETL services. We specialize in Azure Data Factory and Databricks, offering robust solutions for data integration, transformation, and analytics.'
    }
  ];

  ngOnInit() {
    this.startSlideshow();
  }

  startSlideshow() {
    setInterval(() => {
      this.nextSlide();
    }, 5000); // Change slide every 5 seconds
  }

  nextSlide() {
    this.currentSlide = (this.currentSlide + 1) % this.slides.length;
  }

  previousSlide() {
    this.currentSlide = (this.currentSlide - 1 + this.slides.length) % this.slides.length;
  }
}
