import { Component } from '@angular/core';
import { ChildrenOutletContexts } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'techconsultantpro';
  menuActive = false;


  constructor(private contexts: ChildrenOutletContexts) {}

  getRouteAnimationData() {
    return 'openClosePage';
  }

  toggleMenu() {
    this.menuActive = !this.menuActive;
  }
}


