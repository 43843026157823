<div class="contact-container">
    <h2>Contact Us</h2>
    <form #contactForm="ngForm">
      <div class="form-group">
        <label for="name">Name:</label>
        <input type="text" id="name" name="name" ngModel required>
      </div>
  
      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" id="email" name="email" ngModel required>
      </div>
  
      <div class="form-group">
        <label for="subject">Subject:</label>
        <input type="text" id="subject" name="subject" ngModel required>
      </div>
  
      <div class="form-group">
        <label for="message">Message:</label>
        <textarea id="message" name="message" rows="5" ngModel required></textarea>
      </div>
  
      <button type="submit" [disabled]="!contactForm.valid">Submit</button>
    </form>
  </div>
  